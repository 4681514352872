import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";

// mui components
import { Box, IconButton, Stack, Tab, Tabs } from "@mui/material";

// mui icons
import {
	AddOutlined,
	ArrowForwardOutlined,
	CloseOutlined,
} from "@mui/icons-material";

// custom components
import { TabPanel } from "../../tab-panel";
import PrimaryButton from "../../buttons/primary-button";
import Heading6 from "../../typography/heading-06";
import RequiredError from "../../required-error";
import BorrowerInformation from "../../loan-form-card/borrower-information";
import PropertyDetails from "../../loan-form-card/property-details";
import LoanOriginator from "../../loan-form-card/loan-originator";
import LoanProcessor from "../../loan-form-card/loan-processor";
import AdditionalLoanContact from "../../loan-form-card/additional-loan-contact";

// api slices
import { useCreateLoanMutation } from "../../../features/create-loan/createLoanApiSlice";
import { useCreateAdminLoanMutation } from "../../../features/admin/create-loan/createAdminLoanApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { useGetOrganizationDetailsMutation } from "../../../features/organization-detail/organizationDetailApiSlice";
import { useCreateBorrowerPairMutation } from "../../../features/create-borrower-pair/createBorrowerPairApiSlice";
import { useGetBrokerListByTitleMutation } from "../../../features/broker-search-title/brokerSearchTitleApiSlice";
import { useCreateCalyxLoanMutation } from "../../../features/create-calyx-loan/createCalyxLoanApiSlice";

// reducer slices
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";
import {
	selectUserDetails,
	setLoEmail,
	setLpEmail,
} from "../../../features/auth/authSlice";
import { setBrokerListReducer } from "../../../features/broker-list/brokerListSlice";

// helper utils
import {
	requiredFields,
	requiredFieldsForAdditionalContact,
	requiredFieldsForManualLoanOriginator,
} from "../../../utils/requiredFields";
import { handleSetPropertyDetails } from "../../../utils/loan-submission/set-property-details";
import { selectedUserWithLoanChannel } from "../../../utils/common-helpers";
import { LOAN_CHANNEL_CONSTANTS } from "../../../utils/common-constants";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import HelpTextModal from "../../modal/help-text-modal";

// custom styles
import styles from "./index.module.scss";

const LoanApplicationForm = ({
	setLoanApplicationStage,
	setLoanGuid,
	xmlType,
	applicationData,
	setApplicationData,
	organizationName,
	currentStage,
}) => {
	console.log("27 applicationData:", applicationData);
	console.log("27 xml type:", xmlType);

	const dispatch = useDispatch();
	const [createLoan, { isLoading: creatingLoan }] = useCreateLoanMutation();

	const [createAdminLoan, { isLoading: creatingAdminLoan }] =
		useCreateAdminLoanMutation();

	const [createCalyxLoan, { isLoading: creatingCalyxLoan }] =
		useCreateCalyxLoanMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [createBorrowerPair, { isLoading: creatingBorrowerPair }] =
		useCreateBorrowerPairMutation();

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const [loanApplicationData, setLoanApplicationData] = useState([]);

	const [foreignNationalIndex, setForeignNationalIndex] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const [propertyInfo, setPropertyInfo] = useState({});
	const [grossRent, setGrossRent] = useState("");

	const [homeownersInsurance, setHomeownersInsurance] = useState("");
	const [floodInsurance, setFloodInsurance] = useState("");
	const [propertyTaxes, setPropertyTaxes] = useState("");
	const [realEstateTaxes, setRealEstateTaxes] = useState("");
	const [hoaDues, setHoaDues] = useState("");

	const [getBrokerListByTitle] = useGetBrokerListByTitleMutation();
	const [getOrganizationDetails] = useGetOrganizationDetailsMutation();

	const [organizationDetails, setOrganizationDetails] = useState({});

	const [organizationLicense, setOrganizationLicense] = useState("");

	const [loanOriginatorEmail, setLoanOriginatorEmail] = useState("");
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [loanOriginator, setLoanOriginator] = useState({});

	const [loanProcessorEmail, setLoanProcessorEmail] = useState("");
	const [organizationProcessor, setOrganizationProcessor] = useState([]);
	const [loanProcessor, setLoanProcessor] = useState({});

	const [additionalContact, setAdditionalContact] = useState({
		name: "",
		phone: "",
		email: "",
	});

	const [manualOriginator, setManualOriginator] = useState(false);
	const [originatorData, setOriginatorData] = useState({
		orgName: "",
		address: "",
		unitNumber: "",
		city: "",
		state: "",
		zipcode: "",
		orgNmls: "",
		orgStateLicense: "",
		originatorEmail: "",
		originatorFirstName: "",
		originatorLastName: "",
		originatorNmls: "",
		originatorStateLicense: "",
		originatorPhone: "",
	});

	const [helpText, setHelpText] = useState("");
	const [extraHelpText, setExtraHelpText] = useState("");
	const [helpTextOpen, setHelpTextOpen] = useState(false);

	const handleOpenHelpText = () => {
		setHelpTextOpen(true);
	};

	const handleCloseHelpText = () => {
		setHelpTextOpen(false);
	};

	useEffect(() => {
		const getBrokerListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: encodeURIComponent(organizationName),
				title: "Loan Officer",
			}).unwrap();
			dispatch(setBrokerListReducer(searchResults));

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan originator updated:", updatedArr);
			setOrganizationUsers(updatedArr);
		};

		const getProcessorListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: encodeURIComponent(organizationName),
				title: "Loan Processor",
			}).unwrap();

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan processor updated:", updatedArr);
			setOrganizationProcessor(updatedArr);
		};

		const getOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				organizationName: encodeURIComponent(organizationName),
			}).unwrap();
			setOrganizationDetails(searchResults);
		};

		if (
			organizationName !== null &&
			organizationName !== undefined &&
			organizationName !== ""
		) {
			getBrokerListFunc();
			getProcessorListFunc();
			getOrganizationDetailsFunc();
		}
	}, [organizationName]);

	useEffect(() => {
		if (
			Object.keys(loanOriginatorEmail)?.length !== 0 ||
			loanOriginatorEmail !== ""
		) {
			const originatorDetails = organizationUsers?.find(
				(item) => item?.user?.email === loanOriginatorEmail
			);

			setLoanOriginator(originatorDetails);
		}
	}, [loanOriginatorEmail]);

	useEffect(() => {
		if (
			Object.keys(loanProcessorEmail)?.length !== 0 ||
			loanProcessorEmail !== ""
		) {
			const processorDetails = organizationProcessor?.find(
				(item) => item?.user?.email === loanProcessorEmail
			);

			setLoanProcessor(processorDetails);
		}
	}, [loanProcessorEmail]);

	useEffect(() => {
		const sortBorrowerPair = async () => {
			console.log("115 data:", applicationData);

			let pairArr = [];

			applicationData?.applications?.map((item) => {
				pairArr.push(item.borrower);
				pairArr.push(item.coBorrower || item.coborrower);
				return;
			});

			console.log("120 pair arr:", pairArr);

			setLoanApplicationData(pairArr);
			setPropertyInfo(applicationData?.property);
		};

		if (applicationData !== undefined && loanApplicationData?.length === 0) {
			sortBorrowerPair();
		}
	}, [applicationData]);

	const [error, setError] = useState("");

	useEffect(() => {
		console.log("275 application data:", applicationData);

		if (applicationData !== undefined) {
			console.log(
				"275 property type:",
				applicationData?.applications[0]?.propertyUsageType
			);

			setGrossRent(
				applicationData?.applications[0]?.propertyUsageType === "Investor"
					? applicationData?.subjectPropertyGrossRentalIncomeAmount
					: 0
			);

			handleSetPropertyDetails(
				applicationData,
				setHomeownersInsurance,
				setFloodInsurance,
				setPropertyTaxes,
				setRealEstateTaxes,
				setHoaDues
			);
		}
	}, [applicationData]);

	const updateField = async (loanGuid, apiObj) => {
		console.log("310 api obj:", apiObj);

		let salesRepName =
			manualOriginator &&
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
				? organizationDetails?.sales_rep_name
				: loanOriginator?.organization?.sales_rep_name;

		let valuesArr = [
			{ id: "TPO.X3", value: DateTime.now().toFormat("MM-dd-yyyy") },
			{
				id: "CX.PROPERTY.COMMERCIAL.UNITS",
				value: apiObj?.property?.financedNumberOfUnits || "",
			},
			{ id: "NEWHUD.X223", value: "" },
			{ id: "NEWHUD.X224", value: "" },
			{ id: "389", value: "" },
			{ id: "1620", value: "" },
			{ id: 315, value: loanOriginator?.organization?.name },
			{ id: 313, value: loanOriginator?.organization?.city },
			{ id: 321, value: loanOriginator?.organization?.state },
			{ id: 323, value: loanOriginator?.organization?.zip },
			{ id: 3237, value: loanOriginator?.organization?.nmls_id },
			{ id: 95, value: organizationDetails?.sales_rep_email },
			{ id: "CX.LC.AE.Email", value: organizationDetails?.sales_rep_email },
			{ id: 1256, value: salesRepName },
		];

		if (
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
		) {
			valuesArr.push({
				id: "CX.LOLP.SELECT.MANUAL.METHOD",
				value: manualOriginator ? "Y" : "N",
			});
			if (manualOriginator) {
				valuesArr.push({
					id: "315",
					value: originatorData?.orgName,
				});
				valuesArr.push({
					id: "313",
					value: originatorData?.city,
				});
				valuesArr.push({
					id: "321",
					value: originatorData?.state,
				});
				valuesArr.push({
					id: "323",
					value: originatorData?.zipcode,
				});
				valuesArr.push({
					id: "3237",
					value: originatorData?.orgNmls,
				});
			}
		}

		if (
			apiObj?.uldd?.totalMortgagedPropertiesCount === undefined ||
			apiObj?.uldd?.totalMortgagedPropertiesCount === "" ||
			apiObj?.uldd?.totalMortgagedPropertiesCount < 1 ||
			apiObj?.uldd?.totalMortgagedPropertiesCount > 99
		) {
			valuesArr.push({ id: "ULDD.TotalMortgagedPropertiesCount", value: "1" });
		}

		console.log("300 foreign national:", foreignNationalIndex);

		// Loop through the range of indices (0 to 5) for 3 borrower pairs
		for (let index = 0; index < 6; index++) {
			// Create the id based on the index
			const id = `CX.FOREIGNNATLBORR${index + 1}`;

			// Check if the current index is in arrayIndex
			const value = foreignNationalIndex.includes(index) ? "Yes" : "No";

			// Push the object to valuesArr
			valuesArr.push({ id, value });
		}

		if (loanOriginatorEmail !== undefined && loanOriginatorEmail !== "") {
			valuesArr.push({
				id: "TPO.X67",
				value: loanOriginator?.cell_phone,
			});

			console.log("600 loanOriginator:", loanOriginator);

			valuesArr.push({
				id: "2306",
				value: loanOriginator?.state_license,
			});
		}

		console.log("415 loanProcessor email:", loanProcessorEmail);
		console.log("415 loanProcessor:", loanProcessor);

		if (loanProcessorEmail !== undefined && loanProcessorEmail !== "") {
			valuesArr.push({
				id: "TPO.X74",
				value:
					loanProcessor?.user?.first_name +
					" " +
					loanProcessor?.user?.last_name,
			});

			valuesArr.push({
				id: "TPO.X76",
				value: loanProcessor?.user?.email,
			});

			valuesArr.push({
				id: "TPO.X78",
				value: loanProcessor?.phone,
			});

			valuesArr.push({
				id: "TPO.X80",
				value: loanProcessor?.cell_phone,
			});
		}

		console.log("340 loan update values:", valuesArr);

		const updateFieldValues = await updateFields({
			loan_guid: loanGuid,
			field_values: valuesArr,
		}).unwrap();
		console.log("430 file res:", updateFieldValues);

		if (updateFieldValues?.response === "Field values updated successfully.") {
			setError("");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(currentStage + 1);
		} else {
			setError(updateFieldValues?.response);
		}
	};

	const reorderResidencesArray = (arr) => {
		let newArr = [];
		let id = 0;

		for (let i = 0; i < arr.length; i++) {
			newArr.push({ ...arr[i], id: `Residence/${id}` });
			id++;
		}

		return newArr;
	};

	const handleNext = async () => {
		setError("");

		console.log("450 propertyInfo:", propertyInfo);

		if (
			!requiredFields(
				loanApplicationData,
				propertyInfo,
				grossRent,
				homeownersInsurance,
				propertyTaxes,
				loanOriginatorEmail,
				loanProcessorEmail,
				manualOriginator,
				originatorData,
				setError
			)
		) {
			return;
		}

		if (
			manualOriginator &&
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
		) {
			if (!requiredFieldsForManualLoanOriginator(originatorData, setError)) {
				return;
			}
		}

		if (
			additionalContact?.name !== "" &&
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
		) {
			if (!requiredFieldsForAdditionalContact(additionalContact, setError)) {
				return;
			}
		}

		let borrowerPairData = [];

		for (let index = 0; index < loanApplicationData.length; index++) {
			if (index % 2 === 0) {
				if (
					loanApplicationData[index]?.urla2020CitizenshipResidencyType ===
					"Foreign National"
				) {
					loanApplicationData[index].urla2020CitizenshipResidencyType =
						"NonPermanentResidentAlien";
				}

				if (
					loanApplicationData[index + 1]?.urla2020CitizenshipResidencyType ===
					"Foreign National"
				) {
					loanApplicationData[index + 1].urla2020CitizenshipResidencyType =
						"NonPermanentResidentAlien";
				}

				borrowerPairData.push([
					{
						borrower: loanApplicationData[index],
						coborrower: loanApplicationData[index + 1],
					},
				]);
			}
		}

		console.log("285 borrower pair data;", borrowerPairData);

		let residenceArr = [];

		console.log("625 applicationData:", applicationData?.applications);

		for (
			let index = 0;
			index < applicationData?.applications?.length;
			index++
		) {
			console.log("500 data:", applicationData?.applications[index]);
			console.log(
				"500 res data:",
				applicationData?.applications[index]?.residences
			);

			residenceArr.push(
				reorderResidencesArray(applicationData?.applications[index]?.residences)
			);

			console.log("550 residence arr:", residenceArr);
		}

		let applications = [];

		console.log("585 loan app data:", loanApplicationData);

		for (let index = 0; index < loanApplicationData.length; index += 2) {
			console.log("585 index:", index);

			applications.push([
				{
					borrower: {
						...loanApplicationData[index],
						mailingAddressSameAsPresentIndicator: false,
					},
					coBorrower: {
						...loanApplicationData[index + 1],
						mailingAddressSameAsPresentIndicator: false,
					},
					assets: applicationData?.applications[index]?.assets,
					otherAssets: applicationData?.applications[index]?.otherAssets,
					liabilities: applicationData?.applications[index]?.liabilities,
					otherLiabilities:
						applicationData?.applications[index]?.otherLiabilities,
					employment: applicationData?.applications[index]?.employment,
					otherIncomeSources:
						applicationData?.applications[index]?.otherIncomeSources,
					propertyUsageType:
						applicationData?.applications[index]?.propertyUsageType,
					reoProperties: applicationData?.applications[index]?.reoProperties,
					residences: residenceArr[index === 0 ? 0 : index / 2],
				},
			]);
		}

		console.log("450 applications:", applications);

		// Update the monthly payment for the specified fee type
		let updatedFees = [];

		if (
			applicationData?.fees === undefined ||
			applicationData?.fees?.length === 0
		) {
			updatedFees.push({
				feeType: "FloodInsurance",
				monthlyPayment: floodInsurance,
			});

			updatedFees.push({
				feeType: "PropertyTaxes",
				monthlyPayment: propertyTaxes,
			});
		} else {
			applicationData?.fees?.map((fee) => {
				if (fee.feeType === "FloodInsurance") {
					updatedFees.push({ ...fee, monthlyPayment: floodInsurance });
				}

				if (fee.feeType === "PropertyTaxes") {
					updatedFees.push({ ...fee, monthlyPayment: propertyTaxes });
				}

				return fee;
			});
		}

		console.log("600 updated fees:", updatedFees);

		setApplicationData({
			...applicationData,
			subjectPropertyGrossRentalIncomeAmount: grossRent,
			proposedHazardInsuranceAmount: homeownersInsurance,
			proposedDuesAmount: hoaDues,
			fees: updatedFees,
			proposedRealEstateTaxesAmount:
				updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
					?.monthlyPayment || "0",
			applications,
			property: propertyInfo,
			tPO: {
				lOEmail: loanOriginatorEmail,
				lPEmail: loanProcessorEmail,
			},
		});

		let tpoData = {};

		if (
			manualOriginator &&
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
		) {
			tpoData["companyName"] = organizationDetails?.name;
			tpoData["companyId"] = organizationDetails?.company_id;
			tpoData["companyOrganizationId"] = organizationDetails?.organization_id;
			tpoData["companyLegalName"] = organizationDetails?.company_legal_name;
			tpoData["companyAddress"] = organizationDetails?.address;
			tpoData["companyCity"] = organizationDetails?.city;
			tpoData["companyState"] = organizationDetails?.state;
			tpoData["companyZip"] = organizationDetails?.zip;
			tpoData["companyPhone"] = organizationDetails?.phone;
			tpoData["companyAeName"] = organizationDetails?.sales_rep_name;
		} else {
			tpoData["companyName"] = loanOriginator?.organization?.name;
			tpoData["companyId"] = loanOriginator?.organization?.company_id;
			tpoData["companyOrganizationId"] =
				loanOriginator?.organization?.organization_id;
			tpoData["companyLegalName"] =
				loanOriginator?.organization?.company_legal_name;
			tpoData["companyAddress"] = loanOriginator?.organization?.address;
			tpoData["companyCity"] = loanOriginator?.organization?.city;
			tpoData["companyState"] = loanOriginator?.organization?.state;
			tpoData["companyZip"] = loanOriginator?.organization?.zip;
			tpoData["companyPhone"] = loanOriginator?.organization?.phone;
			tpoData["companyAeName"] = loanOriginator?.organization?.sales_rep_name;
		}

		if (
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
		) {
			tpoData["loEmail"] = additionalContact?.email;
			tpoData["loName"] = additionalContact?.name;
			tpoData["loBusinessPhone"] = additionalContact?.phone;
		} else {
			tpoData["loEmail"] = loanOriginator?.user?.email;
			tpoData[
				"loName"
			] = `${loanOriginator?.user?.first_name} ${loanOriginator?.user?.last_name}`;
			tpoData["loBusinessPhone"] = loanOriginator?.phone;
		}

		console.log("650 tpoData:", tpoData);

		let originatorInfoData = {};

		if (
			manualOriginator &&
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
		) {
			originatorInfoData["originatorAddressLineText"] = originatorData?.address;
			originatorInfoData["originatorAddressUnitIdentifier"] =
				originatorData?.unitNumber;
			originatorInfoData["interviewersCompanyStateLicense"] =
				originatorData?.orgStateLicense;
			originatorInfoData["interviewerEmail"] = originatorData?.originatorEmail;
			originatorInfoData["originatorFirstName"] =
				originatorData?.originatorFirstName;
			originatorInfoData["originatorLastName"] =
				originatorData?.originatorLastName;
			originatorInfoData["nmlsLoanOriginatorId"] =
				originatorData?.originatorNmls;
			originatorInfoData["interviewerLicenseIdentifier"] =
				originatorData?.originatorStateLicense;
			originatorInfoData["interviewerPhoneNumber"] =
				originatorData?.originatorPhone;
		} else {
			originatorInfoData["originatorAddressLineText"] =
				loanOriginator?.organization?.address;
			originatorInfoData["interviewerEmail"] = loanOriginator?.user?.email;
			originatorInfoData[
				"interviewerName"
			] = `${loanOriginator?.user?.first_name} ${loanOriginator?.user?.last_name}`;
			originatorInfoData["originatorFirstName"] =
				loanOriginator?.user?.first_name;
			originatorInfoData["originatorLastName"] =
				loanOriginator?.user?.last_name;
			originatorInfoData["nmlsLoanOriginatorId"] = loanOriginator?.nmls_id;

			originatorInfoData["interviewerPhoneNumber"] = loanOriginator?.phone;
			originatorInfoData["interviewerLicenseIdentifier"] = "";
		}

		console.log("690 originatorInfoData:", originatorInfoData);

		let apiObj = {
			user_email: loanOriginatorEmail,
			loan_data: {
				...applicationData,
				subjectPropertyGrossRentalIncomeAmount: grossRent,
				proposedHazardInsuranceAmount: homeownersInsurance,
				proposedDuesAmount: hoaDues,
				fees: updatedFees,
				proposedRealEstateTaxesAmount:
					updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
						?.monthlyPayment || "0",
				applications: applications[0],
				property: propertyInfo,
				tpo: {
					loAeName: loanOriginator?.organization?.sales_rep_id,
					...tpoData,
				},
				...originatorInfoData,
			},
		};

		console.log("265 upload supporting docs:", apiObj);
		// dispatch API and open modal if successful
		try {
			console.log("720 is user details:", userDetails);
			const isAdmin = userDetails?.user_roles?.toLowerCase() === "admin";
			console.log("720 is admin:", isAdmin);

			let fileUpload;

			if (isAdmin) {
				fileUpload = await createAdminLoan({
					...apiObj,
					loan_channel: impersonatedUser?.loan_channel,
				}).unwrap();
			} else {
				fileUpload = await createLoan({ ...apiObj?.loan_data }).unwrap();
			}

			console.log("90 create impersonated loan:", fileUpload);
			console.log("385 loan originator email:", loanOriginatorEmail);
			dispatch(setLoEmail(loanOriginatorEmail));
			setLoanGuid(fileUpload?.GUID);

			// create borrower pair
			if (loanApplicationData?.length >= 3) {
				let borrowerPair;
				for (let index = 1; index < borrowerPairData.length; index++) {
					borrowerPair = await createBorrowerPair({
						loanGuid: fileUpload?.GUID,
						data: {
							...borrowerPairData[index][0],
							assets: applicationData?.applications[index]?.assets,
							employment: applicationData?.applications[index]?.employment,
							liabilities: applicationData?.applications[index]?.liabilities,
							otherIncomeSources:
								applicationData?.applications[index]?.otherIncomeSources,
							otherLiabilities:
								applicationData?.applications[index]?.otherLiabilities,
							propertyUsageType:
								applicationData?.applications[index]?.propertyUsageType,
							reoProperties:
								applicationData?.applications[index]?.reoProperties,
							residences: residenceArr[index],
						},
					}).unwrap();
				}

				console.log("750 borrower pair data:", borrowerPair);
			}

			updateField(fileUpload?.GUID, apiObj?.loan_data);
		} catch (err) {
			console.log("95 err:", err);
			setError(err?.data?.details);
		}
	};

	const handleCreateCalyxLoan = async () => {
		console.log("940 application data:", applicationData);
		console.log("940 loan app data:", loanApplicationData);
		console.log("940 property data:", propertyInfo);

		setError("");

		if (
			!requiredFields(
				loanApplicationData,
				propertyInfo,
				grossRent,
				homeownersInsurance,
				propertyTaxes,
				loanOriginatorEmail,
				loanProcessorEmail,
				manualOriginator,
				originatorData,
				setError
			)
		) {
			return;
		}

		if (
			manualOriginator &&
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
		) {
			if (!requiredFieldsForManualLoanOriginator(originatorData, setError)) {
				return;
			}
		}

		if (
			additionalContact?.name !== "" &&
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
		) {
			if (!requiredFieldsForAdditionalContact(additionalContact, setError)) {
				return;
			}
		}

		let updatedData;

		if (!applicationData.hasOwnProperty("property")) {
			console.log("'property' key not found in the application data object.");
		} else {
			// Update the properties object with values from obj2
			const updatedProperties = {
				...applicationData.property,
				...propertyInfo,
			};

			let updatedBorrowers = [];

			for (let index = 0; index < loanApplicationData.length; index++) {
				if (index % 2 === 0) {
					if (
						loanApplicationData[index]?.urla2020CitizenshipResidencyType ===
						"Foreign National"
					) {
						loanApplicationData[index].urla2020CitizenshipResidencyType =
							"NonPermanentResidentAlien";
					}

					if (
						loanApplicationData[index + 1]?.urla2020CitizenshipResidencyType ===
						"Foreign National"
					) {
						loanApplicationData[index + 1].urla2020CitizenshipResidencyType =
							"NonPermanentResidentAlien";
					}

					updatedBorrowers.push({
						borrower: loanApplicationData[index],
						coborrower: loanApplicationData[index + 1],
					});
				}
			}

			console.log("965 updated borrowers:", updatedBorrowers);

			// Update the monthly payment for the specified fee type
			let updatedFees = [];

			if (
				applicationData?.fees === undefined ||
				applicationData?.fees?.length === 0
			) {
				updatedFees.push({
					feeType: "FloodInsurance",
					monthlyPayment: floodInsurance,
				});

				updatedFees.push({
					feeType: "PropertyTaxes",
					monthlyPayment: propertyTaxes,
				});
			} else {
				applicationData?.fees?.map((fee) => {
					if (fee.feeType === "FloodInsurance") {
						updatedFees.push({ ...fee, monthlyPayment: floodInsurance });
					}

					if (fee.feeType === "PropertyTaxes") {
						updatedFees.push({ ...fee, monthlyPayment: propertyTaxes });
					}

					return fee;
				});
			}
			console.log("1070 updated fees:", updatedFees);

			let tpoData = {};

			if (
				manualOriginator &&
				(selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
			) {
				tpoData["companyName"] = organizationDetails?.name;
				tpoData["companyId"] = organizationDetails?.company_id;
				tpoData["companyOrganizationId"] = organizationDetails?.organization_id;
				tpoData["companyLegalName"] = organizationDetails?.company_legal_name;
				tpoData["companyAddress"] = organizationDetails?.address;
				tpoData["companyCity"] = organizationDetails?.city;
				tpoData["companyState"] = organizationDetails?.state;
				tpoData["companyZip"] = organizationDetails?.zip;
				tpoData["companyPhone"] = organizationDetails?.phone;
				tpoData["companyAeName"] = organizationDetails?.sales_rep_name;
			} else {
				tpoData["companyName"] = loanOriginator?.organization?.name;
				tpoData["companyId"] = loanOriginator?.organization?.company_id;
				tpoData["companyOrganizationId"] =
					loanOriginator?.organization?.organization_id;
				tpoData["companyLegalName"] =
					loanOriginator?.organization?.company_legal_name;
				tpoData["companyAddress"] = loanOriginator?.organization?.address;
				tpoData["companyCity"] = loanOriginator?.organization?.city;
				tpoData["companyState"] = loanOriginator?.organization?.state;
				tpoData["companyZip"] = loanOriginator?.organization?.zip;
				tpoData["companyPhone"] = loanOriginator?.organization?.phone;
				tpoData["companyAeName"] = loanOriginator?.organization?.sales_rep_name;
			}

			if (
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
			) {
				tpoData["loEmail"] = additionalContact?.email;
				tpoData["loName"] = additionalContact?.name;
				tpoData["loBusinessPhone"] = additionalContact?.phone;
			} else {
				tpoData["loEmail"] = loanOriginator?.user?.email;
				tpoData[
					"loName"
				] = `${loanOriginator?.user?.first_name} ${loanOriginator?.user?.last_name}`;
				tpoData["loBusinessPhone"] = loanOriginator?.phone;
			}

			console.log("930 tpoData:", tpoData);

			let originatorInfoData = {};

			if (
				manualOriginator &&
				(selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR)
			) {
				originatorInfoData["originatorAddressLineText"] =
					originatorData?.address;
				originatorInfoData["originatorAddressUnitIdentifier"] =
					originatorData?.unitNumber;
				originatorInfoData["interviewersCompanyStateLicense"] =
					originatorData?.orgStateLicense;
				originatorInfoData["interviewerEmail"] =
					originatorData?.originatorEmail;
				originatorInfoData["originatorFirstName"] =
					originatorData?.originatorFirstName;
				originatorInfoData["originatorLastName"] =
					originatorData?.originatorLastName;
				originatorInfoData["nmlsLoanOriginatorId"] =
					originatorData?.originatorNmls;
				originatorInfoData["interviewerLicenseIdentifier"] =
					originatorData?.originatorStateLicense;
				originatorInfoData["interviewerPhoneNumber"] =
					originatorData?.originatorPhone;
			} else {
				originatorInfoData["originatorAddressLineText"] =
					loanOriginator?.organization?.address;
				originatorInfoData["interviewerEmail"] = loanOriginator?.user?.email;
				originatorInfoData[
					"interviewerName"
				] = `${loanOriginator?.user?.first_name} ${loanOriginator?.user?.last_name}`;
				originatorInfoData["originatorFirstName"] =
					loanOriginator?.user?.first_name;
				originatorInfoData["originatorLastName"] =
					loanOriginator?.user?.last_name;
				originatorInfoData["nmlsLoanOriginatorId"] = loanOriginator?.nmls_id;

				originatorInfoData["interviewerPhoneNumber"] = loanOriginator?.phone;
				originatorInfoData["interviewerLicenseIdentifier"] = "";
			}

			console.log("980 originatorInfoData:", originatorInfoData);

			// Create a new data object with the updated properties
			updatedData = {
				...applicationData,
				subjectPropertyGrossRentalIncomeAmount: grossRent,
				proposedHazardInsuranceAmount: homeownersInsurance,
				proposedDuesAmount: hoaDues,
				fees: updatedFees,
				proposedRealEstateTaxesAmount:
					updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
						?.monthlyPayment || "0",
				applications: updatedBorrowers,
				property: updatedProperties,
				tpo: {
					loAeName: loanOriginator?.organization?.sales_rep_id,
					...tpoData,
				},
				...originatorInfoData,
			};
		}

		console.log("975 updated data:", updatedData);

		console.log("1000 lo email:", loanOriginator?.user?.email);
		console.log("1000 lp email:", loanProcessor?.user?.email);

		let userEmail;

		if (
			impersonatedUser?.email !== null &&
			impersonatedUser?.email !== undefined &&
			impersonatedUser?.email !== ""
		) {
			userEmail = impersonatedUser?.email;
		} else if (
			loanOriginatorEmail !== undefined &&
			loanOriginatorEmail !== ""
		) {
			userEmail = loanOriginatorEmail;
		} else {
			userEmail = userDetails?.email;
		}

		try {
			const fileUpload = await createCalyxLoan({
				email: userEmail,
				data: updatedData,
				loan_channel: selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel,
			}).unwrap();
			console.log("90 create loan:", fileUpload);
			dispatch(setLoEmail(loanOriginator?.user?.email || ""));
			dispatch(setLpEmail(loanProcessor?.user?.email || ""));
			setLoanGuid(fileUpload?.GUID);
			updateField(fileUpload?.GUID, updatedData);
		} catch (err) {
			console.log("975 err:", err);
			setError(err?.data?.errors[0]?.summary);
		}
	};

	const handleAddBorrowerPair = () => {
		setLoanApplicationData([
			...loanApplicationData,
			{ mailingAddressSameAsPresentIndicator: false },
			{ mailingAddressSameAsPresentIndicator: false },
		]);
	};

	const handleRemoveBorrowerPair = () => {
		console.log("1000 tab value:", tabValue);
		console.log("1000 pair count:", loanApplicationData?.length / 2 - 1);

		if (tabValue === loanApplicationData?.length / 2 - 1) {
			setTabValue(tabValue - 1);
		}

		let newArr = loanApplicationData;

		// remove current selected borrower pair with following logic
		// tabvalue ~ loanApplicationData
		// 0 ~ 0, 1
		// 1 ~ 2, 3
		// 2 ~ 4, 5

		if (tabValue === 0) {
			newArr?.splice(tabValue, 2);
		} else {
			newArr?.splice(tabValue * 2, 2);
		}

		setLoanApplicationData([...newArr]);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				<Tabs
					value={tabValue}
					onChange={handleChangeTab}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						style: {
							backgroundColor: "#76D99E",
						},
					}}
				>
					<Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Borrower Pair 1"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>

								{tabValue === 0 && (
									<Stack
										className={styles.closeIconContainer}
										onClick={() =>
											loanApplicationData?.length > 2 &&
											handleRemoveBorrowerPair()
										}
									>
										<CloseOutlined className={styles.closeIcon} />
									</Stack>
								)}
							</Stack>
						}
						{...a11yProps(0)}
					/>
					{loanApplicationData?.length > 2 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 1 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() => handleRemoveBorrowerPair()}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(1)}
						/>
					)}
					{loanApplicationData?.length > 4 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 3"
										fontType="semibold"
										extraClass={
											tabValue === 2 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 2 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() => handleRemoveBorrowerPair()}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(2)}
						/>
					)}

					<Box>
						<IconButton
							className={styles.addPairContainer}
							onClick={() =>
								loanApplicationData?.length < 6 && handleAddBorrowerPair()
							}
							disabled={loanApplicationData?.length > 4}
						>
							<AddOutlined className={styles.addPairIcon} />
						</IconButton>
					</Box>
				</Tabs>

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 0 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							manualOriginator={manualOriginator}
							setManualOriginator={setManualOriginator}
							originatorData={originatorData}
							setOriginatorData={setOriginatorData}
							setHelpText={setHelpText}
							setExtraHelpText={setExtraHelpText}
							handleOpenHelpText={handleOpenHelpText}
							error={error.includes("provide a value")}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
						/>

						{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
							selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR) && (
							<AdditionalLoanContact
								additionalContact={additionalContact}
								setAdditionalContact={setAdditionalContact}
								error={error.includes("provide a value")}
							/>
						)}
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							manualOriginator={manualOriginator}
							setManualOriginator={setManualOriginator}
							originatorData={originatorData}
							setOriginatorData={setOriginatorData}
							setHelpText={setHelpText}
							setExtraHelpText={setExtraHelpText}
							handleOpenHelpText={handleOpenHelpText}
							error={error.includes("provide a value")}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
						/>

						{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
							selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR) && (
							<AdditionalLoanContact
								additionalContact={additionalContact}
								setAdditionalContact={setAdditionalContact}
								error={error.includes("provide a value")}
							/>
						)}
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 4 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							manualOriginator={manualOriginator}
							setManualOriginator={setManualOriginator}
							originatorData={originatorData}
							setOriginatorData={setOriginatorData}
							setHelpText={setHelpText}
							setExtraHelpText={setExtraHelpText}
							handleOpenHelpText={handleOpenHelpText}
							error={error.includes("provide a value")}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
						/>

						{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
							selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR) && (
							<AdditionalLoanContact
								additionalContact={additionalContact}
								setAdditionalContact={setAdditionalContact}
								error={error.includes("provide a value")}
							/>
						)}
					</TabPanel>
				)}
			</Stack>

			{/* loan flow error */}
			{error !== "" && (
				<RequiredError
					headerText="Missing fields"
					text={
						error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted above."
							: error.includes("borrower.dependentCount") &&
							  "Please check your dependent count."
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<PrimaryButton
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() => {
						xmlType === "CALYX MISMO" ? handleCreateCalyxLoan() : handleNext();
					}}
				/>
			</Stack>
			{/* modal */}
			<LoaderModal
				open={
					creatingCalyxLoan ||
					creatingLoan ||
					creatingAdminLoan ||
					isUpdatingFields ||
					creatingBorrowerPair
				}
			/>
			<HelpTextModal
				open={helpTextOpen}
				modalText={helpText}
				extraHelpText={extraHelpText}
				handleClose={handleCloseHelpText}
			/>
		</Stack>
	);
};

export default LoanApplicationForm;
