import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";

// mui components
import { IconButton, InputLabel, Stack } from "@mui/material";

// custom components
import ViewLoanInfoCard from "../info-card";
import OutlinedTextInput from "../../../../components/custom-input/outlined-text-input";
import MaskedInput from "../../../../components/custom-input/masked-input";
import SecondarySelect from "../../../../components/custom-select/secondary-select";
import PrimaryButton from "../../../../components/buttons/primary-button";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import CustomDivider from "../../../../components/custom-divider";
import Heading5 from "../../../../components/typography/heading-05";
import Heading6 from "../../../../components/typography/heading-06";
import Body3 from "../../../../components/typography/body-03";
import Body2 from "../../../../components/typography/body-02";

// mui icons
import {
	ArrowBackOutlined,
	ArrowForwardOutlined,
	AddOutlined,
	DragHandleOutlined,
	HelpOutlineOutlined,
} from "@mui/icons-material";

// api slices
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";

//reducer slices
import { selectUserDetails } from "../../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../../features/admin/impersonated-user/impersonatedUserSlice";

// helper utils
import {
	YNOptions,
	lpcValueOptions,
	bpcValueOptions,
	stateOptions,
	vestingMethodOptions,
} from "../../../../utils/select-options";
import { LOAN_CHANNEL_CONSTANTS } from "../../../../utils/common-constants";
import { selectedUserWithLoanChannel } from "../../../../utils/common-helpers";

// custom modal
import HelpTextModal from "../../../../components/modal/help-text-modal";

// custom styles
import styles from "./index.module.scss";

const ViewLoanFee = ({
	setLoanApplicationStage,
	loanGuid,
	handleOpenLoaderModal,
	handleCloseLoaderModal,
	disabled,
	currentStage,
}) => {
	const params = useParams();
	const loanNumber = params?.loanId;
	const navigate = useNavigate();

	const [field, { isLoading: loadingValues }] = useFieldMutation();

	const [borrowerFirstName, setBorrowerFirstName] = useState("");
	const [borrowerMiddleName, setBorrowerMiddleName] = useState("");
	const [borrowerLastName, setBorrowerLastName] = useState("");

	const [coBorrowerFirstName, setCoBorrowerFirstName] = useState("");
	const [coBorrowerMiddleName, setCoBorrowerMiddleName] = useState("");
	const [coBorrowerLastName, setCoBorrowerLastName] = useState("");

	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [postalCode, setPostalCode] = useState("");

	const [closingDate, setClosingDate] = useState("");
	const [purchaseContractCloseDate, setPurchaseContractCloseDate] =
		useState("");
	const [vestingMethod, setVestingMethod] = useState("");
	const [vestingFull, setVestingFull] = useState("");

	// const [companyTitle, setCompanyTitle] = useState("");
	// const [officerName, setOfficerName] = useState("");
	// const [officerEmail, setOfficerEmail] = useState("");
	// const [officerPhone, setOfficerPhone] = useState("");
	// const [companyAddress, setCompanyAddress] = useState("");
	// const [companyZipcode, setCompanyZipcode] = useState("");
	// const [companyCity, setCompanyCity] = useState("");
	// const [companyState, setCompanyState] = useState("");

	const [escrowCompanyName, setEscrowCompanyName] = useState("");
	const [escrowOfficerName, setEscrowOfficerName] = useState("");
	const [escrowOfficerEmail, setEscrowOfficerEmail] = useState("");
	const [escrowOfficerPhone, setEscrowOfficerPhone] = useState("");
	const [escrowCompanyAddress, setEscrowCompanyAddress] = useState("");
	const [escrowCompanyZipcode, setEscrowCompanyZipcode] = useState("");
	const [escrowCompanyCity, setEscrowCompanyCity] = useState("");
	const [escrowCompanyState, setEscrowCompanyState] = useState("");

	const [borrowerPoa, setBorrowerPoa] = useState("");
	const [coBorrowerPoa, setCoBorrowerPoa] = useState("");

	const [occupancy, setOccupancy] = useState("");
	const [loanPurpose, setLoanPurpose] = useState("");
	const [cashOutProceeds, setCashOutProceeds] = useState("");
	const [propertyOccupiedFamily, setPropertyOccupiedFamily] = useState("");

	const [lpcValue, setLpcValue] = useState("");
	const [lpcAmount, setLpcAmount] = useState("");
	const [bpcValue, setBpcValue] = useState("");
	const [bpcAmount, setBpcAmount] = useState("");

	const [helpText, setHelpText] = useState("");
	const [extraHelpText, setExtraHelpText] = useState("");
	const [helpTextOpen, setHelpTextOpen] = useState(false);

	const handleOpenHelpText = () => {
		setHelpTextOpen(true);
	};

	const handleCloseHelpText = () => {
		setHelpTextOpen(false);
	};

	const [creditReport, setCreditReport] = useState("");
	const [appraisalFee, setAppraisalFee] = useState("");
	const [secondAppraisalFee, setSecondAppraisalFee] = useState("");
	const [processingFee, setProcessingFee] = useState("");
	const [thirdPartyProcessingFee, setThirdPartyProcessingFee] = useState("");

	const [serviceCreditReport, setServiceCreditReport] = useState("");
	const [serviceAppraisalFee, setServiceAppraisalFee] = useState("");
	const [serviceSecondAppraisalFee, setServiceSecondAppraisalFee] =
		useState("");
	const [serviceProcessingFee, setServiceProcessingFee] = useState("");
	const [serviceThirdPartyProcessingFee, setServiceThirdPartyProcessingFee] =
		useState("");

	const [loanOrganization, setLoanOrganization] = useState("");

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const isExecutiveOrManager =
		userDetails?.user_roles?.includes("executive") ||
		userDetails?.user_roles?.includes("manager") ||
		userDetails?.user_roles?.includes("AE") ||
		userDetails?.user_roles?.includes("AM");

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "4000,4001,4002,4004,4005,4006,URLA.X73,12,14,15",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);

			setBorrowerFirstName(
				getValues?.response?.find((o) => o.fieldId === "4000")?.value
			);
			setBorrowerMiddleName(
				getValues?.response?.find((o) => o.fieldId === "4001")?.value
			);
			setBorrowerLastName(
				getValues?.response?.find((o) => o.fieldId === "4002")?.value
			);

			setCoBorrowerFirstName(
				getValues?.response?.find((o) => o.fieldId === "4004")?.value
			);
			setCoBorrowerMiddleName(
				getValues?.response?.find((o) => o.fieldId === "4005")?.value
			);
			setCoBorrowerLastName(
				getValues?.response?.find((o) => o.fieldId === "4006")?.value
			);

			setStreet(
				getValues?.response?.find((o) => o.fieldId === "URLA.X73")?.value
			);
			setCity(getValues?.response?.find((o) => o.fieldId === "12")?.value);
			setState(getValues?.response?.find((o) => o.fieldId === "14")?.value);
			setPostalCode(
				getValues?.response?.find((o) => o.fieldId === "15")?.value
			);
		} catch (err) {}
	};

	useEffect(() => {
		if (loanGuid !== undefined && loanGuid !== "") {
			console.log("180 params:", loanGuid);
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	const handleNext = async () => {
		console.log("502 params id:", loanGuid);

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		navigate(`/loan-summary/${loanGuid}`);
	};

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setClosingDate(findFieldValue(fieldValues, "2966"));
		setPurchaseContractCloseDate(findFieldValue(fieldValues, "763"));

		let vestingMethodValue1 = findFieldValue(
			fieldValues,
			"CX.PORTAL.CORPTRUSTORGTYP"
		);
		let vestingMethodValue2 = findFieldValue(fieldValues, "33");

		if (vestingMethodValue1 !== "") {
			setVestingMethod(vestingMethodValue1);
		} else if (vestingMethodValue2 !== "") {
			setVestingMethod(vestingMethodValue2);
		}

		let finalVestingField = findFieldValue(
			fieldValues,
			"CX.PORTAL.FINAL.VESTING"
		);
		let trustName = findFieldValue(fieldValues, "CX.PORTAL.CORPTRUSTNAME");

		if (finalVestingField !== "") {
			setVestingFull(finalVestingField);
		} else if (trustName !== "") {
			setVestingFull(trustName);
		}

		// setCompanyTitle(findFieldValue(fieldValues, "411"));
		// setOfficerName(findFieldValue(fieldValues, "416"));
		// setOfficerEmail(findFieldValue(fieldValues, "88"));
		// setOfficerPhone(findFieldValue(fieldValues, "417"));
		// setCompanyAddress(findFieldValue(fieldValues, "412"));
		// setCompanyCity(findFieldValue(fieldValues, "413"));
		// setCompanyState(findFieldValue(fieldValues, "1174"));
		// setCompanyZipcode(findFieldValue(fieldValues, "414"));

		let escrowCompanyName = findFieldValue(fieldValues, "610");
		let escrowOfficerName = findFieldValue(fieldValues, "611");
		let escrowOfficerEmail = findFieldValue(fieldValues, "87");
		let escrowOfficerPhone = findFieldValue(fieldValues, "615");
		let escrowCompanyAddress = findFieldValue(fieldValues, "612");
		let escrowCompanyCity = findFieldValue(fieldValues, "613");
		let escrowCompanyState = findFieldValue(fieldValues, "1175");
		let escrowCompanyZipcode = findFieldValue(fieldValues, "614");

		if (
			escrowCompanyName !== "" ||
			escrowOfficerName !== "" ||
			escrowOfficerEmail !== "" ||
			escrowOfficerPhone !== "" ||
			escrowCompanyAddress !== "" ||
			escrowCompanyCity !== "" ||
			escrowCompanyState !== "" ||
			escrowCompanyZipcode !== ""
		) {
			setEscrowCompanyName(escrowCompanyName);
			setEscrowOfficerName(escrowOfficerName);
			setEscrowOfficerEmail(escrowOfficerEmail);
			setEscrowOfficerPhone(escrowOfficerPhone);
			setEscrowCompanyAddress(escrowCompanyAddress);
			setEscrowCompanyCity(escrowCompanyCity);
			setEscrowCompanyState(escrowCompanyState);
			setEscrowCompanyZipcode(escrowCompanyZipcode);
		}

		setBorrowerPoa(findFieldValue(fieldValues, "CX.CPL.USINGPOA.BORROWER"));

		setCoBorrowerPoa(findFieldValue(fieldValues, "CX.CPL.USINGPOA.COBORROWER"));

		let occupancyValue = findFieldValue(fieldValues, "1811");
		let loanPurposeValue = findFieldValue(fieldValues, "19");

		setOccupancy(occupancyValue);
		setLoanPurpose(loanPurposeValue);

		if (
			loanPurposeValue === "Purchase" ||
			loanPurposeValue === "NoCash-Out Refinance"
		) {
			setCashOutProceeds("N");
		} else {
			setCashOutProceeds(
				findFieldValue(fieldValues, "CX.CPL.BUSPURPOSECASHOUT")
			);
		}

		if (
			occupancyValue === "PrimaryResidence" ||
			occupancyValue === "SecondHome"
		) {
			setPropertyOccupiedFamily("Y");
		} else {
			setPropertyOccupiedFamily(
				findFieldValue(fieldValues, "CX.CPL.OCCUPIEDBY")
			);
		}

		setCreditReport(findFieldValue(fieldValues, "640"));
		setAppraisalFee(findFieldValue(fieldValues, "641"));
		setSecondAppraisalFee(findFieldValue(fieldValues, "NEWHUD.X138"));
		setProcessingFee(findFieldValue(fieldValues, "NEWHUD.X136"));
		setThirdPartyProcessingFee(findFieldValue(fieldValues, "NEWHUD.X1285"));

		setServiceCreditReport(findFieldValue(fieldValues, "624"));
		setServiceAppraisalFee(findFieldValue(fieldValues, "617"));
		setServiceSecondAppraisalFee(findFieldValue(fieldValues, "NEWHUD.X1052"));
		setServiceProcessingFee(findFieldValue(fieldValues, "NEWHUD.X1050"));
		setServiceThirdPartyProcessingFee(
			findFieldValue(fieldValues, "NEWHUD.X1284")
		);

		setLpcValue(findFieldValue(fieldValues, "NEWHUD.X223"));
		setLpcAmount(findFieldValue(fieldValues, "NEWHUD.X224"));

		setBpcValue(findFieldValue(fieldValues, "389"));
		setBpcAmount(findFieldValue(fieldValues, "1620") || "0.00");

		setLoanOrganization(findFieldValue(fieldValues, "TPO.X14"));

		handleCloseLoaderModal();
	};

	const getLoanFieldValue = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"763,2966,33,CX.PORTAL.CORPTRUSTORGTYP,CX.PORTAL.FINAL.VESTING,CX.PORTAL.CORPTRUSTNAME,411,416,88,417,412,413,1174,414,610,611,87,615,612,613,1175,614,CX.CPL.USINGPOA.BORROWER,CX.CPL.USINGPOA.COBORROWER,NEWHUD.X251,NEWHUD.X254,650,644,651,645,40,41,43,44,1782,1783,1787,1788,1792,1793,NEWHUD.X1141,NEWHUD.X1225,NEWHUD.X223,NEWHUD.X224,389,1620,640,641,NEWHUD.X138,NEWHUD.X136,NEWHUD.X1285,624,617,NEWHUD.X1052,NEWHUD.X1050,NEWHUD.X1284,TPO.X14,1811,19,CX.CPL.BUSPURPOSECASHOUT,CX.CPL.OCCUPIEDBY",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		if (loanGuid !== undefined && loanGuid !== "") {
			handleOpenLoaderModal();
			getLoanFieldValue(loanGuid);
		}
	}, [loanGuid]);

	let regex = /\(([^)]+)\)/;

	const loanChannel = isExecutiveOrManager
		? loanOrganization?.match(regex)?.[1]?.toUpperCase()
		: selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel;

	const is_loan_cb_whs =
		selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel ===
			LOAN_CHANNEL_CONSTANTS.CB ||
		selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel ===
			LOAN_CHANNEL_CONSTANTS.WHS ||
		(isExecutiveOrManager && loanChannel === LOAN_CHANNEL_CONSTANTS.CB) ||
		(isExecutiveOrManager && loanChannel === LOAN_CHANNEL_CONSTANTS.WHS);

	console.log("390 is_loan_cb_whs:", is_loan_cb_whs);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				<ViewLoanInfoCard
					loanNumber={loanNumber}
					borrower={{
						firstName: borrowerFirstName,
						middleName: borrowerMiddleName,
						lastName: borrowerLastName,
					}}
					coBorrower={{
						firstName: coBorrowerFirstName,
						middleName: coBorrowerMiddleName,
						lastName: coBorrowerLastName,
					}}
					propertyInfo={{
						addressLineText: street,
						city,
						state,
						postalCode,
					}}
				/>

				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Closing Date" fontType="semibold" />
							<Body3 text="Provide details about the loan" />
						</Stack>

						<Stack
							direction="row"
							className={styles.formContainer + " " + styles.datesContainer}
						>
							{/* Closing date */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="closing-date-picker">
									<Heading6
										text="Closing Date"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<DatePicker
									id="closing-date-picker"
									className={
										disabled
											? styles.datepicker + " " + styles.disabledDatepicker
											: styles.datepicker
									}
									value={closingDate || ""}
									onChange={setClosingDate}
									disabled={disabled}
								/>
							</Stack>

							{/* Purchase contract close date */}
							{loanPurpose === "Purchase" && is_loan_cb_whs && (
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="purchase-contract-close-date-picker">
										<Heading6
											text="Purchase Contract Close Date"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<DatePicker
										id="purchase-contract-close-date-picker"
										className={
											disabled
												? styles.datepicker + " " + styles.disabledDatepicker
												: styles.datepicker
										}
										value={purchaseContractCloseDate || ""}
										onChange={setPurchaseContractCloseDate}
										disabled={disabled}
									/>
								</Stack>
							)}
						</Stack>

						<CustomDivider />
					</>
				)}

				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Fee reimbursement" fontType="semibold" />
							<Body3 text="Do you need any fees reimbursed back to you that you incurred on behalf of the borrower(s)?" />
						</Stack>

						<Stack
							direction="column"
							className={styles.formContainer + " " + styles.feeFormContainer}
						>
							{/* credit report */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Credit Report:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="credit-report-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="credit-report-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={creditReport}
											onInputChange={setCreditReport}
											disabled={disabled}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="credit-report-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="credit-report-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceCreditReport}
											handleChange={(event) =>
												setServiceCreditReport(event.target.value)
											}
											disabled={disabled}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* appraisal Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Appraisal Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="appraisal-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="appraisal-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={appraisalFee}
											onInputChange={setAppraisalFee}
											disabled={disabled}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="appraisal-fee-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="appraisal-fee-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceAppraisalFee}
											handleChange={(event) =>
												setServiceAppraisalFee(event.target.value)
											}
											disabled={disabled}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* Second Appraisal Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Second Appraisal Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="second-appraisal-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>
										<MaskedInput
											id="second-appraisal-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={secondAppraisalFee}
											onInputChange={setSecondAppraisalFee}
											disabled={disabled}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="second-appraisal-fee-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="second-appraisal-fee-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceSecondAppraisalFee}
											handleChange={(event) =>
												setServiceSecondAppraisalFee(event.target.value)
											}
											disabled={disabled}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* processing Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Processing Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="processing-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="processing-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={processingFee}
											onInputChange={setProcessingFee}
											disabled={disabled}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="processing-fee-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="processing-fee-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceProcessingFee}
											handleChange={(event) =>
												setServiceProcessingFee(event.target.value)
											}
											disabled={disabled}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* third Party Processing Fee: */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Body2
									text="Third Party Processing Fee:"
									extraClass={styles.sectionHeader}
								/>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="third-party-processing-fee">
											<Heading6
												text="Fees"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="third-party-processing-fee"
											placeholder="$"
											type="currency"
											fullWidth={true}
											value={thirdPartyProcessingFee}
											onInputChange={setThirdPartyProcessingFee}
											disabled={disabled}
										/>
									</Stack>

									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.inlineContainer
										}
									>
										<InputLabel htmlFor="third-party-processing-service-provider">
											<Heading6
												text="Service Provider"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<OutlinedTextInput
											id="third-party-processing-service-provider"
											placeholder="Enter service provider"
											fullWidth={true}
											value={serviceThirdPartyProcessingFee}
											handleChange={(event) =>
												setServiceThirdPartyProcessingFee(event.target.value)
											}
											disabled={disabled}
										/>
									</Stack>
								</Stack>
							</Stack>
						</Stack>

						<CustomDivider />
					</>
				)}

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Vesting" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-vesting-method">
							<Heading6
								text="How will the transaction be vested?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-vesting-method"
							fullWidth={true}
							displayEmpty={true}
							value={vestingMethod}
							handleChange={(event) => setVestingMethod(event.target.value)}
							options={vestingMethodOptions}
							disabled={disabled}
						/>
					</Stack>

					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="input-vesting-wrriten">
							<Heading6
								text="Vesting fully written as:"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-vesting-wrriten"
							placeholder="Type here"
							fullWidth={true}
							value={vestingFull}
							handleChange={(event) => setVestingFull(event.target.value)}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Compensation" fontType="semibold" />
							<Body3 text="Provide details about the loan" />
						</Stack>

						<Stack
							direction="column"
							className={styles.formContainer + " " + styles.feeFormContainer}
						>
							{/* LPC */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Stack
									flexDirection="row"
									alignItems="center"
									className={styles.compensationHeaderContainer}
								>
									<Body2 text="LPC:" extraClass={styles.compensationHeader} />
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<DragHandleOutlined className={styles.lpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.lpcContainer
										}
									>
										<InputLabel htmlFor="select-lpc-percentage">
											<Heading6
												text="LPC Percentage"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<SecondarySelect
											id="select-lpc-percentage"
											fullWidth={true}
											displayEmpty={true}
											value={lpcValue || ""}
											handleChange={(event) => setLpcValue(event.target.value)}
											options={lpcValueOptions}
											disabled={disabled}
										/>
									</Stack>
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<AddOutlined className={styles.lpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.lpcContainer
										}
									>
										<InputLabel htmlFor="input-lpc-amount">
											<Heading6
												text="LPC Dollars"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<MaskedInput
											id="input-lpc-amount"
											placeholder="LPC Amount ($)"
											type="currency"
											value={lpcAmount || ""}
											onInputChange={setLpcAmount}
											disabled={disabled}
										/>
									</Stack>
								</Stack>
							</Stack>

							{/* BPC */}
							<Stack direction="row" className={styles.formSectionContainer}>
								<Stack
									flexDirection="row"
									alignItems="center"
									className={styles.compensationHeaderContainer}
								>
									<Body2
										text="Total BPC Compensation"
										extraClass={styles.compensationHeader}
									/>
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<DragHandleOutlined className={styles.bpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.bpcContainer
										}
									>
										<InputLabel htmlFor="select-bpc-percentage">
											<Heading6
												text="BPC in percentage"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<SecondarySelect
											id="select-bpc-percentage"
											displayEmpty={true}
											value={bpcValue}
											handleChange={(event) => setBpcValue(event.target.value)}
											options={bpcValueOptions}
											disabled={disabled}
										/>
									</Stack>
								</Stack>

								<Stack justifyContent="center" alignItems="center">
									<AddOutlined className={styles.bpcIcon} />
								</Stack>

								<Stack direction="row" className={styles.formContainer}>
									<Stack
										direction="column"
										className={
											styles.inputContainer + " " + styles.bpcContainer
										}
									>
										<Stack direction="row">
											<InputLabel htmlFor="input-bpc-amount">
												<Heading6
													text="BPC in dollars"
													fontType="semibold"
													extraClass={styles.inputHeader}
												/>
											</InputLabel>
											<IconButton
												className={styles.helperIconBtn}
												onClick={() => {
													setHelpText(
														"Values entered in this field will be in addition to the “BPC in percentage” amount charged to the borrower. If the BPC compensation is based solely on a percentage, enter $0.00 in this field."
													);
													setExtraHelpText(
														"For example: If the loan amount is $100,000 the “BPC in percentage” is set to 1%, and you enter $1,000 in the “BPC in dollars” field, the borrower will be charged a total of $2,000 ($1,000 from the dollar amount + $1,000 from the percentage-based amount)."
													);
													handleOpenHelpText();
												}}
											>
												<HelpOutlineOutlined className={styles.helperIcon} />
											</IconButton>
										</Stack>

										<MaskedInput
											id="input-bpc-amount"
											placeholder="$"
											type="currency"
											value={bpcAmount}
											onInputChange={setBpcAmount}
											disabled={disabled}
										/>
									</Stack>
								</Stack>
							</Stack>
						</Stack>

						<CustomDivider />
					</>
				)}

				{is_loan_cb_whs && (
					<>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Escrow" fontType="semibold" />
							<Body3 text="Provide details about the escrow" />
						</Stack>

						<Stack direction="row" className={styles.formContainer}>
							{/* escrow company name */}
							<Stack
								direction="column"
								className={
									styles.inputContainer + " " + styles.fullWidthContainer
								}
							>
								<InputLabel htmlFor="input-company-name">
									<Heading6
										text="Company Name"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-name"
									fullWidth={true}
									value={escrowCompanyName}
									handleChange={(event) =>
										setEscrowCompanyName(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* escrow company address */}
							<Stack
								direction="column"
								className={
									styles.inputContainer + " " + styles.fullWidthContainer
								}
							>
								<InputLabel htmlFor="input-company-address-line">
									<Heading6
										text="Escrow Company Address"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-address-line"
									fullWidth={true}
									value={escrowCompanyAddress}
									handleChange={(event) =>
										setEscrowCompanyAddress(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* escrow company city */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-company-city">
									<Heading6
										text="City"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-city"
									fullWidth={true}
									value={escrowCompanyCity}
									handleChange={(event) =>
										setEscrowCompanyCity(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* escrow company state */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-state">
									<Heading6
										text="State"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-state"
									displayEmpty={true}
									value={escrowCompanyState || ""}
									handleChange={(event) =>
										setEscrowCompanyState(event.target.value)
									}
									options={stateOptions}
									disabled={disabled}
								/>
							</Stack>

							{/* escrow company zipcode */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-company-zipcode">
									<Heading6
										text="Zipcode"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-company-zipcode"
									fullWidth={true}
									value={escrowCompanyZipcode}
									handleChange={(event) =>
										setEscrowCompanyZipcode(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* escrow officer name */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-officer-name">
									<Heading6
										text="Escrow Officer Name"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-officer-name"
									fullWidth={true}
									value={escrowOfficerName}
									handleChange={(event) =>
										setEscrowOfficerName(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* escrow officer phone */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-officer-phone">
									<Heading6
										text="Escrow Officer Cell Phone Number"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="input-officer-phone"
									placeholder="(XXX) XXX-XXXX"
									type="phone"
									value={escrowOfficerPhone}
									onInputChange={setEscrowOfficerPhone}
									disabled={disabled}
								/>
							</Stack>

							{/* escrow officer name */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="input-officer-email">
									<Heading6
										text="Escrow Officer Email ID"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="input-officer-email"
									fullWidth={true}
									value={escrowOfficerEmail}
									handleChange={(event) =>
										setEscrowOfficerEmail(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>

						<CustomDivider />
					</>
				)}

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="POA" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-poa-borrower">
							<Heading6
								text="Will POA be used for Borrower?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-poa-borrower"
							fullWidth={true}
							displayEmpty={true}
							value={borrowerPoa}
							handleChange={(event) => setBorrowerPoa(event.target.value)}
							options={YNOptions}
							disabled={disabled}
						/>
					</Stack>

					{((coBorrowerFirstName !== undefined && coBorrowerFirstName !== "") ||
						(coBorrowerMiddleName !== undefined &&
							coBorrowerMiddleName !== "") ||
						(coBorrowerLastName !== undefined &&
							coBorrowerLastName !== "")) && (
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="select-poa-co-borrower">
								<Heading6
									text="Will POA be used for Co Borrower?"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-poa-co-borrower"
								fullWidth={true}
								displayEmpty={true}
								value={coBorrowerPoa}
								handleChange={(event) => setCoBorrowerPoa(event.target.value)}
								options={YNOptions}
								disabled={disabled}
							/>
						</Stack>
					)}
				</Stack>

				{occupancy === "Investor" && (
					<>
						<CustomDivider />

						{/* Business Purpose Section */}
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading5 text="Business Purpose" fontType="semibold" />
							<Body3 text="Provide details about the loan" />
						</Stack>
						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="select-cashout-business-purpose">
									<Heading6
										text="Cash Out Proceeds being utilized for Business Purposes"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-cashout-business-purpose"
									fullWidth={true}
									displayEmpty={true}
									value={cashOutProceeds || ""}
									handleChange={(event) =>
										setCashOutProceeds(event.target.value)
									}
									options={YNOptions}
									disabled={disabled}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="select-property-occupied-family">
									<Heading6
										text="Will the property be Occupied by a Family Member?"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-property-occupied-family"
									fullWidth={true}
									displayEmpty={true}
									value={propertyOccupiedFamily || ""}
									handleChange={(event) =>
										setPropertyOccupiedFamily(event.target.value)
									}
									options={YNOptions}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</>
				)}
			</Stack>

			{/* footer buttons */}
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text="Back to Loan"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>

			{/* modals */}
			<HelpTextModal
				open={helpTextOpen}
				modalText={helpText}
				extraHelpText={extraHelpText}
				handleClose={handleCloseHelpText}
			/>
		</Stack>
	);
};

export default ViewLoanFee;
